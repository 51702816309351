/* ==========================================================================
15. Footer Style
 ========================================================================== */
 footer {
  background: #292f35;
  padding: 20px 0;
}

.footer-links {
  line-height: 48px;
}

.footer-links li {
  float: left;
}

.footer-links li a {
  color: #fff;
  margin-right: 15px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

.footer-links li a:hover {
  color: #d261c3;
}

.copyright {
  margin-top: 10px;
  float: right;
}

.copyright p {
  color: #fff;
}

.copyright p a {
  color: #fff;
}

.copyright p a:hover {
  color: #d261c3;
}
