.inline{
  display:inline-flex;
}


.control {
  display: inline-flex;
  justify-content: space-between;
  text-align: center;
  padding: 0px 0px 20px;
}


.dimInputTitle{
  margin: auto;
}
.calcSelectTitle{
  padding-right: 1em;
  margin: auto;
}

.select_7s {
  border-radius: 3px;
  display: inline;
}	
.resultTotal {
  font-size: 18px;
}