/* ========================================================================== 
  -2.5 Scroll To Up 
   ========================================================================== */
.back-to-top {
  position: fixed;
  bottom: 95px;
  right: 35px;
  cursor: pointer;
}

.back-to-top i {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  background-color: #d261c3;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
