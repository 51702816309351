/* ==========================================================================
   2.1 Section Title
   ========================================================================== */
   .section {
    padding: 80px 0;
  }
  
  .section-header {
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .section-header .section-title {
    font-size: 42px;
    margin-top: 0;
  /*  text-transform: uppercase; */
    font-weight: 700;
    color: #333;
    position: relative;
  }
  
  .section-header .section-title span {
    color: #d261c3;
  }
  
  .section-header .section-subtitle {
    margin-top: 15px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
  }
  
  .section-header .lines {
    margin: auto;
    width: 70px;
    position: relative;
    border-top: 2px solid #d261c3;
    margin-top: 15px;
  }