/* ==========================================================================
   3. Hero Area
   ========================================================================== */
#hero-area {
  background: url(/public/img/hero-area.jpg) fixed no-repeat;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
  max-height: 500px;
}

#hero-area .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #d261c3;
  opacity: 0.9;
}

#hero-area .contents {
  padding: 100px 0 100px;
}

#hero-area .contents h1 {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 25px;
}

#hero-area .contents p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
}

#hero-area .contents .btn {
  margin: 25px 25px 0px 0px;
  text-transform: uppercase;
}

#hero-area .banner_bottom_btn {
  margin-top: 40px;
}

#hero-area .banner_bottom_btn i {
  color: #fff;
  font-size: 48px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#hero-area .banner_bottom_btn i:hover {
  color: #d261c3;
}
