.number_input{
    position: relative;
    margin-left: 1em;
    margin-right: 1em;
}

.input_number_input{
    box-sizing: border-box;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid #495057;
    outline: none;
	box-shadow:none;
}


.input_number_input:focus {
    border-color: #bbbbbb;
}

.plus_number_input{
    width: 0px;
    border: 5px solid transparent;
    border-bottom: 7px solid #b5b5b5;
    position: absolute;
    top: 5%;
    right: 5px;
    cursor: pointer;
	
}

.minus_number_input{
    width: 0px;
    border: 5px solid transparent;
    border-top: 7px solid #b5b5b5;
    position: absolute;
    top: 60%;
    right: 5px;
    cursor: pointer;
}

.plus_number_input:hover {
    border-bottom-color: #6b6b6b;
}

.minus_number_input:hover {
    border-top-color: #6b6b6b;
}


/*--------------------------------------------------*/

.number_input_mobile{
    display: inline-flex;
    padding: 5px 0;
}

.plus_minus_number_input_mobile{
    margin: auto;
    padding: 0 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 24px;
}

