/* ==========================================================================
  7. Video promo Style
   ========================================================================== */
.callBackDiv {
  background: url(/public/img/testimonial-bg.jpg) fixed no-repeat;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.phoneInputsArea {
  padding-bottom: 1em;
}
.phoneBold {
  font-size: 22px;
  font-weight: bold;
  color: #d261c3;
}
.callbtn {
  margin-left: 3em;
  box-shadow: 0 1px 2px #0a0a0a;
  border: 1px solid #d261c3;
  background: #d261c3;
  position: relative;
  color: #fff;
  /* color: #e01212; */
  z-index: 1;
  border-radius: 30px;
  line-height: 2.2;
  cursor: pointer;
  text-shadow: 1px 1px 1px black, 1px 1px 1px black;
}
.callbtn:hover {
  color: #fff;
  background: #501548;
  border-color: #501548;
}
.callbtnTxt {
  text-shadow: 1px 1px 1px black, 1px 1px 1em #ffffff;
}
.phone {
  width: 200px;
  margin-left: 0;
  padding-left: 10px;
  line-height: 2.5;
  border-radius: 30px;
  font-size: 20px;
}
.phonePic {
  height: 30px;
  padding-right: 1rem;
}
.callConfirm {
  padding-top: 1rem;
}

.phone:focus {
  outline: none;
}
.errorPhone {
  background: #841212;
  color: white;
}

/*----------------------------------------------------------------------*/

.video-promo .video-promo-content h2 {
  text-shadow: 1px 1px 1px black, 1px 1px 1px black;
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.video-promo .video-promo-content p {
  margin-bottom: 30px;
  font-size: 16px;
  text-shadow: 0 0 0 black, 1px 1px 1px black;
}

/* .video-promo {
    background: #d261c3;
    color: #fff;
    overflow: hidden;
    position: relative;
  } */

.video-promo .video-promo-content {
  color: #fff;
}

.video-promo .video-promo-content h2 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.video-promo .video-promo-content p {
  margin-bottom: 30px;
}

.video-promo .video-promo-content .video-popup {
  width: 80px;
  height: 80px;
  text-align: center;
  border: 3px solid #fff;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.video-promo .video-promo-content .video-popup i {
  line-height: 72px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #d261c3;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
