  
  /* ==========================================================================
     4. Navbar Style
     ========================================================================== */
  .navbar-brand {
    position: relative;
    padding: 0px;
  }
  
  .top-nav-collapse {
    background: #fff;
    z-index: 999999;
    top: 0px !important;
    padding: 0;
    box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    background: #fff !important;
  }
  
  .top-nav-collapse .navbar-brand {
    top: 0px;
  }
  
  .top-nav-collapse .navbar-nav .nav-link {
    color: #333 !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  
  .top-nav-collapse .navbar-nav .nav-link:hover {
    color: #d261c3 !important;
  }
  
  .top-nav-collapse .navbar-nav .nav-link.active {
    color: #fff !important;
  }
  
  .indigo {
    background: transparent;
  }
  
  .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
    padding: 0 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 40px;
    border-radius: 30px;
    background: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-expand-lg .navbar-nav li a:hover,
  .navbar-expand-lg .navbar-nav li .active > a,
  .navbar-expand-lg .navbar-nav li a:focus {
    color: #d261c3;
  
  }
  
  .navbar .nav-link.active {
    color: #fff !important;
    background: #d261c3;
    text-shadow: 1px 1px 1px black, 1px 1px 1px black;
  }
  
  .dropdown-toggle::after {
    display: none;
  }
  
  .slicknav_btn {
    border-color: #d261c3;
    color: #d261c3;
    cursor: pointer;
    display: block;
    float: right;
    height: 35px;
    font-size: 24px;
    
    padding-left: 9px;
    position: relative;
    vertical-align: middle;
    width: 44px;
    border: 1px solid;
    border-radius: 0;
    margin: 16px 15px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  
  .slicknav_menu .slicknav_icon-bar {
    background: #d261c3;
  }
  .slicknav_nav li {
    font-size: 16px;
  }
  .slicknav_nav a {
    padding: 5px 15px;
    margin: 2px 5px;
    text-decoration: none;
    color: #666;
  }
  .slicknav_nav {
    line-height: 55px;
    color: #fff;
    font-size: 0.875em;
    list-style: outside none none;
    margin: 0;
    float: left;
    padding: 0;
    width: 100%;
}
.slicknav_menu ul {
  background: #fff;
}
  /* only small tablets */
  @media (min-width: 768px) and (max-width: 991px) {
    #nav-main li a.nav-link {
      padding-top: 18px;
    }
  }
  
  .navbar-toggler {
    display: none;
  }
  
  .mobile-menu {
    display: none;
  }
  
  .slicknav_menu {
    display: none;
  }
  
  @media screen and (max-width: 992px) {
    .navbar-header {
      width: 100%;
    }
    .navbar-brand {
      position: absolute;
      padding: 15px;
      top: 0;
    }
    .navbar-brand img {
      width: 70%;
    }
    #mobile-menu {
      display: none;
    }
    .slicknav_menu {
      display: block;
    }
    .slicknav_nav .active a {
      background: #d261c3;
      color: #fff;
    }
    .slicknav_nav a:hover, .slicknav_nav .active {
      color: #d261c3;
      background: #f8f9fa;
    }
    .slicknav_nav .dropdown li a.active {
      background: #f8f9fa;
      color: #d261c3;
    }
  }
  