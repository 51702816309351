/* ==========================================================================
  8. Portfolio Section
   ========================================================================== */
   #portfolios .mix {
    padding: 10px;
  }
  
  #portfolios .portfolio-item .shot-item {
    margin: 0px;
  }
  
  #portfolio .mix {
    display: none;
  }
  
  .controls {
    text-align: center;
    padding: 0px 0px 20px;
  }
  
  .controls .active {
    color: #d261c3 !important;
    border-color: #d261c3;
    background: transparent;
  }
  
  .controls .btn {
    text-transform: uppercase;
    margin: 2px;
  }
  
  .controls:hover {
    cursor: pointer;
  }
  
  .portfolio-img {
    overflow: hidden;
    display: block;
    position: relative;
  }
  
  .portfolio-img img {
    width: 100%;
  }
  
  .shot-item {
    margin-right: 15px;
    border-radius: 4px;
    background: #fff;
    position: relative;
  }
  
  .shot-item img {
    width: 100%;

  }
  
  .shot-item .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
  }
  
  .shot-item:hover .overlay {
    opacity: 1;
  }
  
  .overlay .item-icon {
    height: 48px;
    width: 48px;
    line-height: 48px;
    color: #d261c3;
    left: 50%;
    margin-left: -24px;
    margin-top: -24px;
    top: 50%;
    position: absolute;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border: 1px solid #d261c3;
    border-radius: 50%;
  }
  
  .overlay .item-icon:hover {
    background: #d261c3;
    color: #fff;
  }
  
  .shot-item:hover .item-icon {
    visibility: visible;
    opacity: 1;
  }

  /* ========================================================================== */

  .modal {
 
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: inline;
    /* display: flex; */
    justify-content: center;
    /* display: inline-table; */
    padding-top: 5rem;
    /* padding-bottom: 5rem; */
    box-sizing: border-box;



  }

  
  .modal-body {
    flex: inherit;
    /* padding: 2rem; */
    background: #fff;
 
    /* border-radius: 5px;
    background: #fff; */
    /* width: 400px;
    height: 200px; */
  }

  .modalItem{
    margin: auto;
  }
  
  .modalItemMobile{
    width:100%;
    height:100%;
    position:relative;
    top:25%;
  }

  .pheader {
    font-size: 16px;
    text-align: center;
  }
  .modal-img{
    width: 100%;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    /* height: 0; */
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}
.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}