/* ==========================================================================
  14. Contact Us
  ========================================================================== */
.messengerPic{
  height: 30px;
}

  .address{
    font-size:20px;
  }

  .contact-us h3 {
    font-size: 36px;
    margin-bottom: 45px;
  }
  
  .contact-us p span {
    color: #d261c3;
    padding-left: 10px;
  }
  .contact-us p a {
    color: #d261c3;
  
  }
  
  #contact {
    /* background: #343a40;
    color: #fff; */
    position: relative;
  }
  
  .form-control {
    width: 100%;
    margin-bottom: 10px;
    padding: 14px;
    border-color: #fff;
    border-radius: 0px;
    color: #fff;
    background: transparent;
    font-size: 14px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .form-control:focus {
    box-shadow: none;
    color: #fff;
    border-color: #d261c3;
    background: transparent;
    outline: none;
  }
  
  .btn.disabled, .btn:disabled {
    opacity: 1;
  }
  
  .text-danger {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .list-unstyled li {
    color: #d9534f;
  }
  
  .googleMap{
    height:450px;
  }