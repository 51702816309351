/* ==========================================================================
  6. Features Section Style
   ========================================================================== */
#features {
  background: url(/public/img/bg1.jpg) fixed no-repeat;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
}

#features .section-title {
  color: #fff;
}

#features .section-subtitle {
  color: #fff;
}

#features .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#features .box-item {
  padding: 15px 0;
}

#features .box-item .icon {
  border: 1px solid #d261c3;
  text-align: center;
  float: left;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .icon i {
  color: #d261c3;
  font-size: 24px;
  line-height: 60px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text {
  padding-left: 80px;
}

#features .box-item .text h4 {
  color: #d261c3;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 22px;
}

#features .box-item .text p {
  font-size: 14px;
  line-height: 26px;
}

#features .box-item:hover .icon {
  background: #d261c3;
}

#features .box-item:hover .icon i {
  color: #ffffff;
}

#features .show-box img {
  position: absolute;
  bottom: -120px;
}
