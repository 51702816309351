/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */
   @import url("https://fonts.googleapis.com/css?family=Poppins:400,700");
   /* ==========================================================================
      2. GENERAL
      ========================================================================== */
   body {
     font-family: 'Poppins', sans-serif;
     color: #333;
     font-size: 14px;
     font-weight: 400;
     background: #fff;
     overflow-x: hidden;
   }
   
   html {
     overflow-x: hidden;
   }
   
   p {
     font-size: 14px;
     line-height: 26px;
   }
   
   a:hover, a:focus {
     color: #d261c3;
   }
   /*
   
   #61D2B4
   */
   a {
     -webkit-transition: all 0.2s linear;
     -moz-transition: all 0.2s linear;
     -o-transition: all 0.2s linear;
     transition: all 0.2s linear;
   }
   
   h1, h2, h3, h4, h5 {
     font-family: 'Poppins', sans-serif;
     font-size: 40px;
     font-weight: 700;
     letter-spacing: 1px;
   }
   
   ul {
     margin: 0;
     padding: 0;
   }
   
   ul li {
     list-style: none;
   }
   
   a:hover,
   a:focus {
     text-decoration: none;
     outline: none;
   }
   
   a:not([href]):not([tabindex]) {
     color: #fff;
   }
   
   a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
     color: #d261c3;
   }

   .textShadow {
    text-shadow: 1px 1px 1px black, 1px 1px 1px black;
}

/* ==========================================================================
   2.2 Buttons
   ========================================================================== */
   .btn {
    font-size: 14px;
    padding: 11px 40px;
    border-radius: 0px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: inline-block;
  }
  
  .btn:focus,
  .btn:active {
    box-shadow: none;
    outline: none;
    color: #fff;
  }
  
  .btn-common {
    text-shadow: 1px 1px 1px black, 1px 1px 1px black;
    border: 1px solid #d261c3;
    background: #d261c3;
    position: relative;
    color: #fff;
    z-index: 1;
    border-radius: 30px;
  }
  
  .btn-common:hover {
    color: #fff;
  /*  background: #33b391;
    border-color: #33b391;
  */
    background: #501548;
    border-color: #501548;
    
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
  }
  
  .btn-border {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
  }
  
  .btn-border:hover {
    border: 2px solid #fff;
    color: #fff;
    background-color: #d261c3;
  }
  
  .btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
  }
  
  .btn-rm {
    padding: 7px 0px;
    color: #999;
    text-transform: capitalize;
  }
  
  .btn-rm i {
    vertical-align: middle;
  }
  
  button:focus {
    outline: none !important;
  }
  
  .clear {
    clear: both;
  }
  
  /* ==========================================================================
     2.3 Social Iocns
     ========================================================================== */
  .social-icons {
    margin-top: 40px;
  }
  
  .social-icons ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .social-icons ul li {
    display: inline;
  }
  
  .social-icons ul li a {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 15px;
    border-radius: 50%;
    line-height: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #ddd;
    font-size: 16px;
  }
  
  .social-icons ul li a:hover {
    color: #fff;
  }
  
  .facebook a {
    color: #4867AA;
  }
  
  .facebook a:hover {
    background: #4867AA;
  }
  
  .twitter a {
    color: #1DA1F2;
  }
  
  .twitter a:hover {
    background: #1DA1F2;
  }
  
  .google-plus a {
    color: #DD4D42;
  }
  
  .google-plus a:hover {
    background: #DD4D42;
  }
  
  .youtube a {
    color: #DF2926;
  }
  
  .youtube a:hover {
    background: #DF2926;
  }
  
  .linkedin a {
    color: #007BB6;
  }
  
  .linkedin a:hover {
    background: #007BB6;
  }
  
  .pinterest a {
    color: #BD081C;
  }
  
  .pinterest a:hover {
    background: #BD081C;
  }
  
  .dribbble a {
    color: #EA4C89;
  }
  
  .dribbble a:hover {
    background: #EA4C89;
  }
  
  .behance a {
    color: #0B7CFF;
  }
  
  .behance a:hover {
    background: #0B7CFF;
  }