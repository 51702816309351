/* ==========================================================================
  5. Services section
   ========================================================================== */
   .item-boxes {
    text-align: center;
    padding: 0px 15px;
    margin-bottom: 15px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  
  .item-boxes .icon {
    width: 60px;
    height: 60px;
    text-align: center;
    border: 1px solid #f1f1f1;
    display: inline-block;
    border-radius: 30px;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .item-boxes .icon i {
    font-size: 26px;
    line-height: 60px;
    color: #d261c3;
  }
  
  .item-boxes h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .item-boxes:hover .icon {
    background: #d261c3;
  }
  
  .item-boxes:hover .icon i {
    color: #fff;
  }